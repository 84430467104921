
  <!--services-->
  <aside id="services" class="scrollto text-center" data-enllax-ratio=".2">

    <div class="row clearfix">

        <div class="section-heading">
            <h3>Dienstleistungen</h3>
            <h2 class="section-title">Unsere Dienstleistungen umfassen eine breite Palette kreativer und funktionaler Lösungen für Ihre individuellen Bedürfnisse</h2>
        </div>

        <!--service-->
        <blockquote class="col-3 testimonial classic">
            <img src="../assets/images/service-images/service-1.png" alt="User"/>
            <h4>Architektur &amp; Innenarchitektur</h4>
            <footer></footer>
            <p>Von der Konzeption bis zur Umsetzung gestalten wir innovative und nachhaltige Räume, 
                die Ihre ästhetischen und funktionalen Anforderungen erfüllen. Mit einem kreativen und zugleich präzisen Ansatz sorgen wir dafür, 
                dass Wohn-, Arbeits- und öffentliche Räume nicht nur optisch überzeugen, sondern auch höchsten Komfort und Effizienz bieten.</p>
            
        </blockquote>

        <!--Service-->
        <blockquote class="col-3 testimonial classic">
            <img src="../assets/images/service-images/service-2.png" alt="User"/>
            <h4>Videoaufnahmen</h4>
            <footer></footer>
            <p>Unser erfahrenes Videoteam schafft beeindruckende visuelle Erlebnisse, die weit über einfache Aufnahmen hinausgehen. 
                Wir setzen Geschichten in Bewegung und fangen Emotionen durch sorgfältig geplante Szenen ein. 
                Ob Unternehmensvideos, Werbespots oder Eventdokumentationen – wir verwandeln Ihre Vision in lebendige Bilder, 
                die nachhaltig begeistern..</p>
        </blockquote>
        <!-- End of Service-->

        <!--Service-->
        <blockquote class="col-3 testimonial classic">
            <img src="../assets/images/service-images/service-3.png" alt="User"/>
            <h4>Webdesign</h4>
            <footer></footer>
            <p>Wir gestalten moderne, benutzerfreundliche Websites, die Ihre Marke optimal repräsentieren. Unser Fokus liegt darauf, ansprechende und intuitive digitale Erlebnisse zu schaffen,
                 die nicht nur ästhetisch überzeugen, sondern auch funktional und leicht zugänglich sind. 
                 Von der Konzeption bis zur finalen Umsetzung sorgen wir dafür, dass Ihre Online-Präsenz kraftvoll und effektiv ist.</p>
        </blockquote>
        <!-- End of Service-->

        <!--Service-->
        <blockquote class="col-3 testimonial classic">
            <img src="../assets/images/service-images/service-4.png" alt="User"/>
            <h4>Fotografie</h4>
            <footer></footer>
            <p>Unsere Fotografen verstehen es, mehr als nur den Augenblick festzuhalten – sie fangen die Essenz Ihrer Geschichte ein. 
                Mit einem geschulten Blick für Details und einem kreativen Ansatz setzen wir Momente kunstvoll in Szene, 
                um die emotionale Wirkung Ihrer Botschaft zu verstärken. Ob Porträt-, Event-, Produkt- oder Architekturfotografie, 
                wir schaffen Bilder, die sowohl ästhetisch ansprechend als auch ausdrucksstark sind und nachhaltig in Erinnerung bleiben.</p>
        </blockquote>
        <!-- End of Service-->

        <!--Service-->
        <blockquote class="col-3 testimonial classic">
            <img src="../assets/images/service-images/service-5.png" alt="User"/>
            <h4>Grafikdesign</h4>
            <footer></footer>
            <p>Unser Grafikdesign bringt Ihre Markenidentität visuell zur Geltung. Ob es sich um die Entwicklung eines Logos,
                 die Gestaltung von Printmaterialien oder die Schaffung eines einheitlichen Corporate Designs handelt – wir arbeiten daran, Ihre Botschaft durch kreative und durchdachte visuelle Konzepte klar und einprägsam zu vermitteln. Unser Ziel ist es,
                 Designs zu schaffen, die nachhaltig beeindrucken und Ihre Marke stärken.</p>
        </blockquote>
        <!-- End of Service-->

        <!--Service-->
        <blockquote class="col-3 testimonial classic">
            <img src="../assets/images/service-images/service-6.png" alt="User"/>
            <h4>Video Editing</h4>
            <footer></footer>
            <p>Unser Video Editing-Service verwandelt Ihre Rohaufnahmen in fesselnde Geschichten, die Ihre Zielgruppe ansprechen. Wir schneiden und bearbeiten Videomaterial, um emotionale, visuell ansprechende und professionell gestaltete Videos zu erstellen. 
                Ob für Werbeclips, Unternehmenspräsentationen oder kreative Projekte – wir stellen sicher, 
                dass Ihre Inhalte auf den Punkt gebracht und eindrucksvoll präsentiert werden.</p>
        </blockquote>
        <!-- End of Service-->

    </div>

</aside>
<!--End of services-->
import { Component } from '@angular/core';
import { Router, NavigationEnd, Event as RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; // Dies ist wichtig für ngModel


import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { AboutComponent } from './components/about/about.component';
import { ClientsComponent } from './components/clients/clients.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ServicesComponent } from './components/services/services.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { NavComponent } from './components/nav/nav.component';
import { StrategieComponent } from './components/strategie/strategie.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from "./components/contact/contact.component";
import { datenschutzComponent } from "./components/datenschutz/datenschutz.component";
import { NotfoundComponent } from './components/notfound/notfound.component';
import { HomeComponent } from "./components/home/home.component";



@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule, HeaderComponent, AboutComponent, ClientsComponent,
    ServicesComponent, GalleryComponent, datenschutzComponent, NotfoundComponent,
    NavComponent, StrategieComponent, FooterComponent, ContactComponent, FormsModule, HomeComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent {
  showMainContent = false;
  constructor(private router: Router) {
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     // Wir verwenden die URL, um zu entscheiden, ob `app-home` angezeigt werden soll
    //     this.showMainContent = event.url === '/' || event.url === '/home';
    //   }
    // });
  }

  onActivate(event: any): void {
    // Dies könnte genutzt werden, um spezielle Aktivierungslogik zu haben,
    // momentan leer, da wir bereits die URL-Prüfung durchführen
  }

  onDeactivate(event: any): void {
    // Dies könnte genutzt werden, um spezielle Deaktivierungslogik zu haben,
    // momentan leer, da wir bereits die URL-Prüfung durchführen
  }
}

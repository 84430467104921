import { NgModule } from '@angular/core';
import { Routes,RouterModule } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';
import { AboutComponent } from './components/about/about.component';
import { ClientsComponent } from './components/clients/clients.component';
import { PricingComponent } from './components/pricing/pricing.component';
import { ServicesComponent } from './components/services/services.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { NavComponent } from './components/nav/nav.component';
import { StrategieComponent } from './components/strategie/strategie.component';
import { FooterComponent } from './components/footer/footer.component';
import { ContactComponent } from "./components/contact/contact.component";
import { datenschutzComponent } from "./components/datenschutz/datenschutz.component";
import { NotfoundComponent } from './components/notfound/notfound.component';
import { HomeComponent } from './components/home/home.component';



export const routes: Routes = [
    {title:"Glance Studio| Home:",path:'', component:HomeComponent },
    {title:"Glance Studio| Home:",path:'home', component:HomeComponent },
    // {title:"Glance Studio| About:",path:'about', component:AboutComponent },
    // {title:"Glance Studio| Clients:",path:'clients', component:ClientsComponent },
    // {title:"Glance Studio| Services:",path:'services', component:ServicesComponent },
    // {title:"Glance Studio| Navigation:",path:'nav', component:NavComponent },
    // {title:"Glance Studio| Strategie:",path:'strategie', component:StrategieComponent },
    // {title:"Glance Studio| Footer:",path:'footer', component:FooterComponent },
    // {title:"Glance Studio| Kontact:",path:'contact', component:ContactComponent },
    {title:"Glance Studio| Datenschutz:",path:'Datenschutz', component: datenschutzComponent},
    {title:"Glance Studio| Nicht gefunden!:",path:'**', component:NotfoundComponent },
  ];



  @NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule { }
<header id="banner" class="scrollto clearfix" data-enllax-ratio=".5">
    <script src='https://www.google.com/recaptcha/api.js' async defer></script>

    <!--Banner Content-->
    <div id="banner-content" class="row clearfix">
        <div class="col-38">

            <div class="section-heading">
                <video autoplay muted loop playsinline  muted="" id="bannerVideo">
                    <source src="../../../assets/images/banner-images/banner-video-1.mp4" type="video/mp4">
                    Ihr Browser unterstützt kein Video.
                </video>
                <h1>Glance Studio,
                <br>Wo Kreativität auf Innovation trifft</h1>
                <h2>Bei Glance Studio vereinen wir Architektur, Videoaufnahmen, Webdesign, Fotografie und Grafikdesign unter einem Dach.
                Unser Ziel ist es, Ihre Visionen zum Leben zu erwecken, indem wir kreative Lösungen anbieten, die sowohl ästhetisch ansprechend als auch funktional sind.</h2>
                <!--Call to Action-->
                <a href="mailto:kontakt@glancestudio.de?subject= Terminanfrage für erstes Gespräch" class="button">Kostenloses Erstgespräch</a>
                <!--End Call to Action-->
    
            </div>


        </div>

    </div>
    <!--End of Row-->
</header>
  <!--Content Section-->
  <div id="strategie" class="scrollto clearfix">

    <div class="row no-padding-bottom clearfix">


        <!--Content Left Side-->
        <div class="col-3">
            <!--User Testimonial-->
            <blockquote class="testimonial text-right bigtest">
                <q>Gott steckt im Detail</q>
                <footer>— Ludwig Mies van der Rohe (1886–1969)</footer>
            </blockquote>
            <!-- End of Testimonial-->

        </div>
        <!--End Content Left Side-->

        <!--Content of the Right Side-->
        <div class="col-3">
            <div class="section-heading">
                <h3>Unsere Strategie</h3>
                <h2 class="section-title">Ihr Partner für visionäre Gestaltung und umfassende Kreativlösungen</h2>
                <p class="section-subtitle">Wir konzentrieren uns auf das Wesentliche, weil wir daran glauben, dass es den Unterschied macht.</p>
            </div>
            <p>Bei Glance Studio bringen wir Architektur, visuelle Medien, Webentwicklung und Designkunst zusammen,
                 um maßgeschneiderte Projekte zu realisieren, die sowohl in ihrer Funktionalität als auch in ihrer Ästhetik überzeugen.
                 Mit einem ganzheitlichen Ansatz und einem klaren Fokus auf Ihre individuellen Bedürfnisse verwandeln wir Visionen in greifbare Ergebnisse. 
            </p>
            <p>
                Unsere interdisziplinäre Expertise ermöglicht es uns, kreative Konzepte zu entwickeln, die nicht nur beeindrucken, sondern auch nachhaltig wirken. 
            </p>
            
            <!-- Just replace the Video ID "UYJ5IjBRlW8" with the ID of your video on YouTube (Found within the URL) -->
             <!--
            <a href="#" data-videoid="UYJ5IjBRlW8" data-videosite="youtube" class="button video link-lightbox">
                WATCH VIDEO <i class="fa fa-play" aria-hidden="true"></i>
            </a>
            -->
        </div>
        <!--End Content Right Side-->

        <div class="col-3">
            <video autoplay muted loop playsinline  muted="" id="strategieVideo">
                <source src="../assets/images/GlanceStudio.mp4" type="video/mp4">
                Ihr Browser unterstützt kein Video.
            </video>
            <!--<img src="../assets/images/GlanceStudio.mp4" alt="Dancer"/>-->
        </div>

    </div>


</div>
<!--End of Content Section-->
<!--Introduction-->
<section id="about" class="introduction scrollto">

    <div class="row clearfix">

        <div class="col-3">
            <div class="section-heading">
                <h3>ÜBER UNS</h3>
                <h2 class="section-title">Unser Fokus: Kreativität</h2>
                <p class="section-subtitle">Kreativität steht bei uns an erster Stelle. In allem, was wir tun, streben wir danach, Grenzen zu überschreiten und neue Wege zu gehen. 
                    Wir glauben, dass jedes Projekt einzigartig ist und eine individuelle, kreative Herangehensweise erfordert. 
                    Deshalb arbeiten wir eng mit Ihnen zusammen, um sicherzustellen, dass das Endprodukt nicht nur Ihren Erwartungen entspricht, sondern diese übertrifft.</p>
            </div>

        </div>

        <div class="col-2-3">

            <!--Icon Block-->
            <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.1s">
                <!--Icon-->
                <div class="icon">
                    <i class="fa fa-users fa-2x"></i>
                </div>
                <!--Icon Block Description-->
                <div class="icon-block-description">
                    <h4>Individuelle Beratung</h4>
                    <p>Zu Beginn jedes Projekts stehen wir Ihnen mit einer umfassenden Beratung zur Seite. 
                        Wir hören Ihre Ideen und Ziele aufmerksam an und bieten Ihnen maßgeschneiderte Vorschläge, 
                        um Ihre Visionen zu konkretisieren und Ihre Anforderungen präzise zu erfassen.</p>
                </div>
            </div>
            <!--End of Icon Block-->

            <!--Icon Block-->
            <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.3s">
                <!--Icon-->
                <div class="icon">
                    <i class="fa fa-spinner fa-2x"></i>
                </div>
                <!--Icon Block Description-->
                <div class="icon-block-description">
                    <h4>Bedarfsanalyse</h4>
                    <p>In dieser Phase führen wir eine gründliche Analyse Ihrer Bedürfnisse und Anforderungen durch. 
                        Wir bewerten bestehende Herausforderungen und identifizieren Chancen, um sicherzustellen, 
                        dass unsere Lösungen genau auf Ihre geschäftlichen und kreativen Ziele abgestimmt sind.</p>
                </div>
            </div>
            <!--End of Icon Block-->

            <!--Icon Block-->
            <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.5s">
                <!--Icon-->
                <div class="icon">
                    <i class="fa fa-cogs fa-2x"></i>
                </div>
                <!--Icon Block Description-->
                <div class="icon-block-description">
                    <h4>Konzeptentwicklung</h4>
                    <p>Basierend auf der Bedarfsanalyse entwickeln wir kreative und funktionale Konzepte, 
                        die Ihre Visionen in greifbare Ergebnisse umsetzen. 
                        Wir legen großen Wert darauf, innovative Ideen zu präsentieren und die beste Lösung für Ihre spezifischen Anforderungen zu finden.</p>
                </div>
            </div>
            <!--End of Icon Block-->

            <!--Icon Block-->
            <div class="col-2 icon-block icon-top wow fadeInUp" data-wow-delay="0.5s">
                <!--Icon-->
                <div class="icon">
                    <i class="fa fa-rocket fa-2x"></i>
                </div>
                <!--Icon Block Description-->
                <div class="icon-block-description">
                    <h4>Umsetzung und Feedback</h4>
                    <p>Nachdem das Konzept finalisiert ist, beginnen wir mit der Umsetzung. 
                        Während des gesamten Prozesses halten wir Sie regelmäßig auf dem Laufenden und integrieren Ihr Feedback, um sicherzustellen
                        , dass das Endprodukt Ihren Erwartungen entspricht und höchste Qualität bietet.</p>
                </div>
            </div>
            <!--End of Icon Block-->

        </div>

    </div>


</section>
<!--End of Introduction-->
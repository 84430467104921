import { Component } from '@angular/core';
import { HeaderComponent } from './../header/header.component';
import { AboutComponent } from './../about/about.component';
import { ClientsComponent } from './../clients/clients.component';
import { PricingComponent } from './../pricing/pricing.component';
import { ServicesComponent } from './../services/services.component';
import { GalleryComponent } from './../gallery/gallery.component';
import { NavComponent } from './../nav/nav.component';
import { StrategieComponent } from './../strategie/strategie.component';
import { FooterComponent } from './../footer/footer.component';
import { ContactComponent } from "./../contact/contact.component";

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [HeaderComponent,AboutComponent,ClientsComponent,ServicesComponent
    ,NavComponent,StrategieComponent,FooterComponent,ContactComponent
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {

}

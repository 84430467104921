
<section id="contact" class="scrollto clearfix ">
	<div class="secondary-color text-center scrollto clearfix">
		<div class="row no-padding-bottom clearfix">
			<div class="col-2">
				<div class="section-heading">
					<h3>Kontakt</h3>
					<h2 class="section-title">Kontaktieren Sie uns</h2>
					<p class="section-subtitle">Wenn Sie Fragen haben, nutzen Sie einfach die folgenden Kontaktmöglichkeiten.</p>
				</div>
				<form>
					<div class="col-2">
						<!-- <input type="button" class="form-field btn" value="Submit"> -->
						<form (ngSubmit)="sendMessage()">
							<div class="form-row">
								<input type="text" class="input-field" name="name" [(ngModel)]="contact.name" placeholder="Name" required>
								<input type="email" class="input-field" name="email" [(ngModel)]="contact.email" placeholder="Email" required>
								<input type="phone" class="input-field" name="email" [(ngModel)]="contact.phone" placeholder="Telefon (optional)">
								<textarea class="input-field" name="message" [(ngModel)]="contact.message" placeholder="Nachricht" required></textarea>
								<div class="g-recaptcha" [attr.data-sitekey]="siteKey"></div>
							</div>
							<button type="submit" class="btn btn-submit">Absenden</button>
						</form>	  						  
					</div>
				</form>
			</div>
			<div class="col-2">
				<div>
					<!--Email-->
					<div class="col-1">
						<div class="icon">
							<i class="fa fa-envelope-open fa-1x"></i>&nbsp;
							<span class="protectedEmail" title="E-Mail:"></span>
							<a href="#" class="cryptedmail"
								data-name="kontakt"
								data-domain="glancestudio"
								data-tld="de"
								onclick="window.location.href = 'mailto:' + this.dataset.name + '@' + this.dataset.domain + '.' + this.dataset.tld; return false;">
							</a>
						</div>							
					</div>

					<!--Telefon-->
					<div class="col-1">
						<div class="icon">
							<i class="fa fa-phone fa-1x"></i>&nbsp;
							<span class="protectedNumber" title="0561 49942065"></span>
						</div>							
					</div>

					<!--Address-->
					<div class="col-1">
						<div class="icon">
							<i class="fa fa-map fa-1x"></i>&nbsp;
							<span class="protectedAddress" title="34128 Kassel"></span>
						</div>							
					</div>

				</div>
			</div>
			<div class="col-2">
				<div class="col-1">
					<!--<img src="../../../assets/images/contact/contact-glance-studion.mp4">-->
					<div id="banner">
						<video autoplay muted loop playsinline id="contactVideo">
							<source src="../../../assets/images/contact/contact-glance-studion.mp4" type="video/mp4">
							Ihr Browser unterstützt kein Video.
						</video>
						<!-- Weitere Inhalte deines Banners hier -->
					</div>
				</div>
			</div>
			
		</div>
	</div>


</section>




<!--Footer-->
<footer id="landing-footer" class="clearfix">
    <nav class="row clearfix">
        <ul class="text-center">
            <ul class="text-center">
                <p id="copyright" >Copyright ©2024 <a href="https://www.glancestudio.de">Glance Studio</a></p>
            </ul>
            <ul class="">
                <p id="home-link" ><a routerLink="/home" routerLinkActive="active" aria-current="page">Home</a></p>
                <p id="datenschutz" ><a routerLink="/Datenschutz" routerLinkActive="active" aria-current="page">Datenschutzerklärung</a></p>
                <li><a href="#contact">Kontakt</a></li>
            </ul>
        </ul>
  
        <!--Social Icons in Footer-->
   <!--
        <ul class="col-2 social-icons">
            <li>
                <a target="_blank" title="Facebook" href="https://www.facebook.com/username">
                    <i class="fa fa-facebook fa-1x"></i><span>Facebook</span>
                </a>
            </li>
            <li>
                <a target="_blank" title="Google+" href="http://google.com/+username">
                    <i class="fa fa-google-plus fa-1x"></i><span>Google+</span>
                </a>
            </li>
            <li>
                <a target="_blank" title="Twitter" href="http://www.twitter.com/username">
                    <i class="fa fa-twitter fa-1x"></i><span>Twitter</span>
                </a>
            </li>
            <li>
                <a target="_blank" title="Instagram" href="http://www.instagram.com/username">
                    <i class="fa fa-instagram fa-1x"></i><span>Instagram</span>
                </a>
            </li>
            <li>
                <a target="_blank" title="behance" href="http://www.behance.net">
                    <i class="fa fa-behance fa-1x"></i><span>Behance</span>
                </a>
            </li>
        </ul>
        -->
        <!--End of Social Icons in Footer-->
    </nav>
  </footer>

  <!--End of Footer-->
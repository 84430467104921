import { Component } from '@angular/core';

@Component({
  selector: 'app-strategie',
  standalone: true,
  imports: [],
  templateUrl: './strategie.component.html',
  styleUrl: './strategie.component.css'
})
export class StrategieComponent {

}

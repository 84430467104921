<div id="header" class="nav-collapse">
    <div class="row clearfix">
        <div class="col-1">

            <!--Logo-->
            <div id="logo">

                <!--Logo that is shown on the banner-->
                <img src="../assets/images/logo.png" id="banner-logo" alt="Landing Page" width="100" height="1000"/>
                <!--End of Banner Logo-->

                <!--The Logo that is shown on the sticky Navigation Bar-->
                <img src="../assets/images/logo-2.png" id="navigation-logo" alt="Landing Page" width="100" height="1000"/>
                <!--End of Navigation Logo-->

            </div>
            <!--End of Logo-->

            <aside>

                <!--Social Icons in Header-->
                <!--
                <ul class="social-icons">
                    <li>
                        <a target="_blank" title="Facebook" href="https://www.facebook.com/username">
                            <i class="fa fa-facebook fa-1x"></i><span>Facebook</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" title="Google+" href="http://google.com/+username">
                            <i class="fa fa-google-plus fa-1x"></i><span>Google+</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" title="Twitter" href="http://www.twitter.com/username">
                            <i class="fa fa-twitter fa-1x"></i><span>Twitter</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" title="Instagram" href="http://www.instagram.com/username">
                            <i class="fa fa-instagram fa-1x"></i><span>Instagram</span>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" title="behance" href="http://www.behance.net">
                            <i class="fa fa-behance fa-1x"></i><span>Behance</span>
                        </a>
                    </li>
                </ul>
                -->
                <!--End of Social Icons in Header-->

            </aside>

            <!--Main Navigation-->
            <nav id="nav-main">
                <ul>
                    <li>
                        <a routerLink="/home">Home</a>
                    </li>
                    <li>
                        <a href="#about">Über uns</a>
                    </li>
                    <li>
                        <a href="#services">Dienstleistungen</a>
                    </li>
                    <!--
                    <li>
                        <a href="#gallery">Gallery</a>
                    </li> -->
                    <li>
                        <a href="#strategie">Unsere Strategie</a>
                    </li>
                    <li>
                        <a href="#clients">Kunden</a>
                    </li>
                  <!--  <li>
                        <a href="#pricing">Pricing</a>
                    </li> -->
                    <li>
                        <a href="#contact">Kontakt</a>
                    </li>
                </ul>
            </nav>
            <!--End of Main Navigation-->

            <div id="nav-trigger"><span></span></div>
            <nav id="nav-mobile"></nav>

        </div>
    </div>
</div><!--End of Header-->



  <!--Clients-->
  <section id="clients" class="scrollto clearfix">
    <div class="row clearfix">

        <div class="col-3">

            <div class="section-heading">
                <h3>VERTRAUEN</h3>
                <h2 class="section-title">Unsere geschätzten Partner</h2>
                <p class="section-subtitle">Wir arbeiten mit einer Vielzahl von Unternehmen zusammen und bieten maßgeschneiderte Lösungen,
                     die den unterschiedlichen Anforderungen unserer Kunden gerecht werden. 
                     Unsere Partner schätzen unsere kreative Herangehensweise und unser Engagement für exzellente Ergebnisse, 
                     die sowohl funktional als auch ästhetisch überzeugen.</p>
            </div>

        </div>

        <div class="col-2-3">

            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo1.png" alt="Company"/>
                <div class="client-overlay"><span>Liegen</span></div>
            </a>
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo2.jpg" alt="Company"/>
                <div class="client-overlay"><span>Kranz</span></div>
            </a>
            <!--
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo3.png" alt="Company"/>
                <div class="client-overlay"><span>The Man</span></div>
            </a>
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo4.png" alt="Company"/>
                <div class="client-overlay"><span>Mustache</span></div>
            </a>
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo5.png" alt="Company"/>
                <div class="client-overlay"><span>Goat</span></div>
            </a>
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo6.png" alt="Company"/>
                <div class="client-overlay"><span>Justice</span></div>
            </a>
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo7.png" alt="Company"/>
                <div class="client-overlay"><span>Ball</span></div>
            </a>
            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo8.png" alt="Company"/>
                <div class="client-overlay"><span>Cold</span></div>
            </a>

            <a href="#" class="col-3">
                <img src="../assets/images/company-images/company-logo9.png" alt="Company"/>
                <div class="client-overlay"><span>Cold</span></div>
            </a>
            -->
        </div>

    </div>
</section>
<!--End of Clients-->

import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';  // FormsModule importieren
import { HttpClient } from '@angular/common/http';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';



@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [FormsModule, RecaptchaModule, RecaptchaFormsModule],     // FormsModule in den imports hinzufügen
  templateUrl: './contact.component.html',
  styleUrl: './contact.component.css'
})
export class ContactComponent {
  captchaResponse!: string;
  siteKey: string = '6LfGKjwqAAAAAMdvmJzlSerhrDFt9SbZoIhLGo3L';  // Setze hier deinen Site-Key ei
  captchaError: boolean = false;  // Zustand für Fehlermeldung


  contact = {
    name: '',
    email: '',
    message: '',
    phone:''
  };

  constructor(private http: HttpClient) {}

 // sendMessage() {
  // In your Angular service or component that handles the submission
  sendMessage() {
    this.http.post('http://localhost:3000/send-email', { /* data including captcha response */ })
        .subscribe({
            next: (response) => {
                console.log('Email sent successfully');
            },
            error: (error) => {
                console.error('Error sending email:', error);
                alert('Failed to send email. Please verify the CAPTCHA and try again.');
            }
        });
  }

}